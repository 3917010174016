import { FC, useState } from 'react';

import { twMerge } from 'tailwind-merge';

import { Document, EllipsisGrid } from '@assets/icons';
import { LockIcon, MoreOptions } from '@components/index';
import { AssetType } from '@pages/home-page/enums';
import { useLockAction } from '@pages/home-page/hooks/use-lock-action';
import { DataItem } from '@pages/home-page/types';

import DocumentPreviewModal from '../document-items/DocumentPreview';

interface AttributeRowProps {
  item: DataItem;
  workspaceId: number;
  isDraggable?: boolean;
  isGrouped?: boolean;
  handleDelete: (id: number, assetType: AssetType) => void;
  handleEdit?: (item: DataItem, assetType: AssetType) => void;
  isStacked?: boolean;
}

const AttributeRow: FC<AttributeRowProps> = ({
  item,
  isDraggable = true,
  workspaceId,
  handleDelete,
  handleEdit,
  isGrouped = false,
  isStacked = false,
}) => {
  const [openDocumentPreview, setOpenDocumentPreview] = useState(false);
  const [isLocked, setIsLocked] = useState(Boolean(item.isLocked));

  const { mutate: toggleLock, isLoading } = useLockAction(isLocked);

  const isInherited = item.parentClientId !== workspaceId;

  const showLock = !isInherited && !!item.parentClientId;

  const showDelete = !isLocked;

  const showEdit = !isInherited && !isLocked;

  const showMoreActions = showDelete || showEdit || showLock;

  const onLockError = () => {
    setIsLocked(Boolean(item.isLocked));
  };

  const handleToggle = (id: number) => {
    setIsLocked(prev => !prev);
    toggleLock({
      attributeId: id,
      onError: onLockError,
    });
  };

  return (
    <div
      key={item.id}
      className={twMerge(
        'w-full flex flex-1 flex-row rounded border p-2 items-start relative mb-2 min-h-20 border-gray-2',
        isStacked ? 'bg-lightest' : 'bg-white',
      )}
    >
      {isDraggable && (
        <div className="absolute left-0 top-1/2 transform -translate-y-1/2 mx-2">
          <EllipsisGrid className="h-4 w-4 flex-shrink-0" />
        </div>
      )}
      <div
        className={twMerge(
          'w-[45%] flex flex-row gap-4 items-center',
          isGrouped ? '' : 'pl-6',
        )}
      >
        <div
          className="text-sm flex-grow overflow-hidden text-ellipsis whitespace-normal pr-10 text-justify"
          style={{ wordBreak: 'break-word' }}
        >
          {item.details}
        </div>
      </div>
      <div
        className={`w-[20%] flex-grow overflow-hidden text-ellipsis whitespace-normal text-sm ${!isGrouped ? 'pl-4' : 'pl-1'}`}
        style={{ wordBreak: 'break-word' }}
      >
        {item?.parentClientName}
        {item.sourceDocument?.id && (
          <div
            className="flex text-[13px] text-blue-2 border-blue-3 items-start bg-blue-4 rounded-xl p-2 mt-1 font-semibold w-fit max-w-[200px] text-ellipsis whitespace-normal break-words cursor-pointer"
            onPointerDown={e => e.stopPropagation()}
            onClick={e => {
              e.stopPropagation();
              if (item?.sourceDocument?.imageUrls?.length)
                setOpenDocumentPreview(true);
            }}
          >
            <Document className="mr-2 mt-1" />
            <span className="w-full mt-0.5">{item.sourceDocument?.label}</span>
          </div>
        )}
      </div>
      <div
        className={twMerge(
          'w-[15%] text-sm flex-grow overflow-hidden text-ellipsis whitespace-normal',
          isGrouped ? 'pl-3' : 'pl-5',
        )}
        style={{ wordBreak: 'break-word' }}
      >
        {item.createdBy}
      </div>
      <div className="w-[15%] items-start text-sm pl-10">
        {item.createdAt || '-'}
      </div>
      <div
        className={`flex justify-end w-[5%] ${!isGrouped ? 'pr-2' : 'pr-0'} `}
        onPointerDown={e => e.stopPropagation()}
      >
        {showMoreActions && (
          <MoreOptions
            enabled={isLocked}
            classOverride="!items-start"
            isLoading={isLoading}
            onToggle={() => handleToggle(item.id)}
            showLock={showLock}
            {...(showDelete && {
              onClickDelete: () => handleDelete(item.id, AssetType.KNOWLEDGE),
            })}
            {...(showEdit && {
              onClickEdit: () => handleEdit?.(item, AssetType.KNOWLEDGE),
            })}
          />
        )}
      </div>
      <span
        className={`absolute cursor-default bottom-0 right-0 p-2 rounded-tl-lg rounded-br-md ${isInherited ? 'bg-gray-2' : 'bg-lighter'}`}
      >
        <LockIcon isInherited={isInherited} isLocked={isLocked} />
      </span>
      {openDocumentPreview && (
        <DocumentPreviewModal
          isOpen={openDocumentPreview}
          onClose={() => setOpenDocumentPreview(false)}
          fileName={item?.sourceDocument?.label || ''}
          imageUrls={item?.sourceDocument?.imageUrls || []}
          externalAssetId={item?.sourceDocument?.externalAssetId}
        />
      )}
    </div>
  );
};

export default AttributeRow;
