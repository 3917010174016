import { FC, useState } from 'react';

import { twMerge } from 'tailwind-merge';

import { LockIcon, MoreOptions } from '@components/index';
import { AssetType } from '@pages/home-page/enums';
import { useHandleLock } from '@pages/home-page/hooks/use-handle-lock';
import { DataItem } from '@pages/home-page/types';
import { isValidImage } from '@pages/home-page/utils';

import ContentCell from '../ContentCell';
import ImageVideoPreviewModal from '../ImageVideoPreviewModal';

interface AssetRowProps {
  dataItem: DataItem;
  imageClassName: string;
  currentWorkspaceId: number;
  handleDelete: (id: number, assetType: AssetType) => void;
  handleEdit?: (item: DataItem, assetType: AssetType) => void;
  isGrouped?: boolean;
}

const AssetRow: FC<AssetRowProps> = ({
  dataItem,
  imageClassName,
  currentWorkspaceId,
  handleEdit,
  handleDelete,
  isGrouped = false,
}) => {
  const [previewItem, setPreviewItem] = useState<DataItem | null>(null);

  const { isLocked, handleToggle, isLoading } = useHandleLock(
    Boolean(dataItem.isLocked),
    dataItem.id,
  );

  const isInherited = dataItem.parentClientId !== currentWorkspaceId;

  const isImageType =
    dataItem.assetType === AssetType.LOGO ||
    dataItem.assetType === AssetType.PRODUCT;

  const showPreview = isImageType
    ? isValidImage(dataItem?.url as string)
    : dataItem.assetType === AssetType.VIDEO;

  const showEdit =
    dataItem.assetType !== AssetType.FONT && !isInherited && !isLocked;

  const showDelete = !isLocked;

  const showLock = !isInherited && !!dataItem.parentClientId;

  const showMoreActions = showPreview || showEdit || showDelete || showLock;

  const renderDetailItem = (item: DataItem) => {
    if ([AssetType.LOGO, AssetType.PRODUCT].includes(item.assetType)) {
      if (item?.thumbnailUrl && isValidImage(item.thumbnailUrl))
        return (
          <img
            src={item?.thumbnailUrl}
            alt={`${item.assetType}_${item.id}`}
            className={twMerge('max-h-[50px] max-w-[50px]', imageClassName)}
          />
        );
    }

    if (item.assetType === AssetType.VIDEO) {
      if (item?.thumbnailUrl)
        return (
          <img
            src={item?.thumbnailUrl}
            alt={`${item.assetType}_${item.id}`}
            className={twMerge('max-h-[50px] max-w-[50px]', imageClassName)}
          />
        );
    }

    if (item.assetType === AssetType.COLORS) {
      return (
        <div className="flex items-center border w-fit border-gray-2 px-2 py-1 rounded">
          <div
            className="w-6 h-6 rounded-full m-1.5 drop-shadow mr-2.5 border"
            style={{ background: item.details }}
          />
          {item.details}
        </div>
      );
    }
  };

  return (
    <>
      <div className="flex items-center border rounded py-2 shadow-sm px-2 my-1 bg-white border-gray-2 relative min-h-20">
        <td className="w-[45%] flex align-center pr-4 pl-1">
          <div className="flex gap-4 items-center text-sm w-full">
            <ContentCell content={dataItem?.name} classOverride="flex-grow" />
            <div className="flex-shrink-0">{renderDetailItem(dataItem)}</div>
          </div>
        </td>
        <td className="w-[20%] flex-grow pl-4">
          <ContentCell content={dataItem?.parentClientName} />
        </td>
        <td className="w-[15%] flex-grow pl-5">
          <ContentCell content={dataItem?.createdBy} />
        </td>
        <td className={twMerge('w-[15%]', isGrouped ? 'pl-11' : 'pl-10')}>
          <ContentCell content={dataItem?.createdAt || '-'} />
        </td>
        <td
          className={twMerge(
            'flex justify-end w-[5%]',
            isGrouped ? '' : 'pr-2',
          )}
          onPointerDown={e => e.stopPropagation()}
        >
          {showMoreActions && (
            <MoreOptions
              enabled={isLocked}
              isLoading={isLoading}
              onToggle={handleToggle}
              showLock={showLock}
              {...(showDelete && {
                onClickDelete: () =>
                  handleDelete(dataItem.id, dataItem.assetType),
              })}
              {...(showEdit && {
                onClickEdit: () => handleEdit?.(dataItem, dataItem.assetType),
              })}
              {...(showPreview && {
                onClickPreview: () => setPreviewItem(dataItem),
              })}
            />
          )}
        </td>
        <span
          className={`absolute cursor-default bottom-0 right-0 p-2 rounded-tl-lg rounded-br-md ${isInherited ? 'bg-gray-2' : 'bg-lighter'}`}
        >
          <LockIcon isInherited={isInherited} isLocked={isLocked} />
        </span>
      </div>
      {previewItem && (
        <ImageVideoPreviewModal
          isOpen={!!previewItem}
          onClose={() => setPreviewItem(null)}
          fileName={previewItem?.name as string}
          url={previewItem?.url as string}
          externalAssetId={previewItem?.externalAssetId}
          isVideoPreview={dataItem.assetType === AssetType.VIDEO}
        />
      )}
    </>
  );
};

export default AssetRow;
