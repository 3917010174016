import { FC, useEffect, useState } from 'react';

import { v4 as uuid_v4 } from 'uuid';

import { Info } from '@assets/icons';
import { Button } from '@components/index';
import { ASSET_INFO, tiffFileExtensions } from '@pages/home-page/constants';
import { AssetType } from '@pages/home-page/enums';
import { useBrandLibrary } from '@pages/home-page/hooks/use-brand-library';
import { DataItem, Item } from '@pages/home-page/types';
import { getFileExt } from '@utils/file';

import { CustomUploader } from './CustomUploader';
import FontItemPreview from './FontItemPreview';
import ItemDetailsPreview from './ItemDetailsPreview';
import { useUpdateBrandAsset } from '@pages/home-page/hooks/use-update-brand-asset';
import DocumentUploadItemPreview from './DocumentUploadItemPreview';

interface ItemUploadModalProps {
  isEdit?: boolean;
  assetType: AssetType;
  onCloseClick: () => void;
  defaultValues?: DataItem;
}

const ItemUploadModal: FC<ItemUploadModalProps> = ({
  isEdit = false,
  assetType,
  onCloseClick,
  defaultValues,
}) => {
  const [items, setItems] = useState<Item[]>([]);

  useEffect(() => {
    if (defaultValues?.id) {
      setItems([
        {
          key: defaultValues.id,
          name: defaultValues.name,
          assetType: assetType,
          thumbnailUrl: (defaultValues?.thumbnailUrl ||
            defaultValues?.url) as string,
        },
      ]);
    } else {
      setItems([]);
    }
  }, [defaultValues]);

  const { handleSave, isUploading } = useBrandLibrary();
  const { mutateAsync: updateBrandAsset, isLoading } = useUpdateBrandAsset();

  const disableSave =
    items.length === 0 ||
    items.some(item => !item.name.trim()) ||
    isUploading ||
    isLoading;

  const handleItemNameChange = (key: string | number, name: string): void => {
    const index = items.findIndex(item => item.key === key);
    const tempItems = [...items];
    tempItems[index] = { ...tempItems[index], name };
    setItems(tempItems);
  };

  const handleUpload = (files: File[]) => {
    if (!files?.length) {
      return;
    }

    const validFiles: {
      key: string;
      name: string;
      thumbnailUrl: string;
      assetType: AssetType;
      isTiffFile?: boolean;
    }[] = [];
    const invalidFiles: File[] = [];
    const allowedFileTypes = ASSET_INFO[assetType].fileTypes;

    files.forEach(file => {
      const fileType = getFileExt(file.name).toLowerCase();
      if (allowedFileTypes.includes(fileType)) {
        validFiles.push({
          key: uuid_v4(),
          name: file.name,
          assetType: assetType,
          thumbnailUrl: URL.createObjectURL(file),
          isTiffFile: tiffFileExtensions.includes(fileType),
        });
      } else {
        invalidFiles.push(file);
      }
    });
    if (validFiles.length > 0) {
      setItems(prevFiles => [...prevFiles, ...validFiles]);
    }
  };

  const handleOnClose = (): void => {
    setItems([]);
    onCloseClick();
  };

  const onSave = async () => {
    if (isEdit) {
      await updateBrandAsset({
        assetId: defaultValues?.id as number,
        fileName: items[0].name,
      });
      handleOnClose();
    } else {
      await handleSave(items, handleOnClose, assetType);
    }
  };

  const renderAssetItem = (item: Item) => {
    const handleRemoveItem = (): void => {
      setItems(prevData =>
        prevData.filter(prevItem => prevItem.key !== item.key),
      );
    };

    switch (item.assetType) {
      case AssetType.FONT:
        return (
          <FontItemPreview
            key={item.key}
            font={item}
            handleDeleteFont={handleRemoveItem}
          />
        );
      case AssetType.RAW_FILE:
        return (
          <DocumentUploadItemPreview
            key={item.key}
            item={item}
            handleDeleteItem={handleRemoveItem}
          />
        );
      default:
        return (
          <ItemDetailsPreview
            key={item.key}
            item={item}
            handleItemNameChange={handleItemNameChange}
            handleDeleteItem={handleRemoveItem}
            isEdit={isEdit}
          />
        );
    }
  };

  return (
    <>
      {items?.length > 0 && (
        <div className="flex flex-col max-h-[250px] overflow-y-auto mb-2 pt-2">
          {items.map(item => renderAssetItem(item))}
        </div>
      )}
      <div className="pb-8 px-8">
        {!isEdit && (
          <>
            <div className="mb-1 font-semibold text-sm">
              Upload {assetType === AssetType.FONT ? 'font' : 'file'}
            </div>
            <CustomUploader
              allowableFileExt={ASSET_INFO[assetType as AssetType].accept}
              onClick={handleUpload}
            />
            <div className="flex gap-1 items-center text-gray-5 mb-6 mt-1">
              <Info className="text-gray-6" />
              <div className="text-sm text-gray-3 font-medium">
                {`Supported file types ${ASSET_INFO[
                  assetType as AssetType
                ].fileTypes
                  .join(', ')
                  .toUpperCase()}`}
              </div>
            </div>
          </>
        )}
        <Button
          label="Save"
          type="submit"
          isDisabled={disableSave}
          isLoading={isUploading || isLoading}
          classOverride="font-bold mt-[7px]"
          variant="contained"
          onClick={onSave}
        />
      </div>
    </>
  );
};

export default ItemUploadModal;
