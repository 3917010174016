import { ChangeEvent, FC, useState } from 'react';

import { useRecoilValue } from 'recoil';
import { twMerge } from 'tailwind-merge';

import { Import } from '@assets/icons';
import {
  Button,
  Input,
  LockedButton,
  PermissionAccess,
  Spinner,
} from '@components/index';
import { UserPermissions } from '@enums/user-permissions';
import { AssetType } from '@pages/home-page/enums';
import { useImportAssetIds } from '@pages/home-page/hooks/use-import-asset-ids';
import { workspace } from '@store/atoms/workspace';

const ID_LIMIT = 25;

interface AssetIdInputProp {
  showImportButton?: boolean;
  classOverride?: string;
  showLabel?: boolean;
  assetType?: AssetType;
  onCloseClick?: () => void;
}

const AssetIdInput: FC<AssetIdInputProp> = ({
  showImportButton = false,
  classOverride = '',
  showLabel = true,
  assetType,
  onCloseClick = () => {},
}) => {
  const currentWorkspace = useRecoilValue(workspace);
  const [assetIds, setAssetIds] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const hasAssets = assetIds?.trim().length > 0;

  const { mutateAsync: importIds, isLoading } = useImportAssetIds();

  const disableImportClick = isLoading || !hasAssets || !!errorMessage;

  const handleImport = async () => {
    if (assetIds.length) {
      const distinctIds = assetIds
        .replaceAll(' ', '')
        .split(',')
        .reduce((list: string[], current: string) => {
          if (!list.includes(current)) {
            list.push(current.toLowerCase().trim());
          }

          return list;
        }, []);

      if (distinctIds.length > ID_LIMIT) {
        setErrorMessage(
          `You have exceeded the upload limit of ${ID_LIMIT} TAB IDs. Please adjust your selection to proceed.`,
        );
      } else {
        setErrorMessage('');
        try {
          await importIds({
            tabIds: distinctIds,
            clientUuid: currentWorkspace.uuid,
            ...(assetType && { assetType }),
          });
        } finally {
          onCloseClick();
          setAssetIds('');
        }
      }
    } else {
      setErrorMessage('Asset ID is required');
    }
  };

  const handleOnchange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    setAssetIds(e.target.value);
  };

  return (
    <div className="w-full">
      <div className="flex items-end justify-center w-full gap-4">
        <PermissionAccess
          permission={UserPermissions.MANAGE_BRAND_LIBRARY}
          AllowedContent={
            <div className="flex align-top w-full gap-2">
              <Input
                containerClass={twMerge('w-full', classOverride)}
                value={assetIds}
                placeholder="Enter Asset ID(s). Eg: 12345, 45622, etc..."
                onChange={handleOnchange}
                error={errorMessage}
                label={showLabel ? "Upload from 'The Asset Bank'" : ''}
                onKeyDown={e => {
                  if (e.key !== 'Enter' || disableImportClick) return;
                  e.preventDefault();
                  handleImport();
                }}
                disabled={isLoading}
                customInputClass="focus:border-primary focus:bg-lightest"
              />
              {!showImportButton && (
                <div
                  className={twMerge(
                    'flex items-center justify-center rounded size-10',
                    disableImportClick
                      ? 'bg-gray-2'
                      : 'bg-primary cursor-pointer',
                  )}
                  onClick={disableImportClick ? undefined : handleImport}
                >
                  {isLoading ? (
                    <Spinner color="bg-gray-1" />
                  ) : (
                    <Import
                      className={`${disableImportClick ? 'text-dark-3' : 'text-white'}`}
                    />
                  )}
                </div>
              )}
            </div>
          }
          DeniedContent={
            <>
              <Input
                containerClass={twMerge('w-full', classOverride)}
                placeholder="Eg: 12345, 23456, etc..."
                label={showLabel ? "Upload from 'The Asset Bank'" : ''}
                disabled
              />
              <LockedButton buttonClassname="w-fit" />
            </>
          }
        />
      </div>
      {showImportButton && (
        <div className="mt-4">
          <Button
            label="Import"
            variant="contained"
            isLoading={isLoading}
            isDisabled={disableImportClick}
            onClick={handleImport}
            classOverride="!text-sm"
          />
        </div>
      )}
    </div>
  );
};
export default AssetIdInput;
